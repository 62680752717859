:root {
    --green-dark: #41b06e;
    --green-light: #8decb4;
    --navy-blue: #141e46;
    --creamy: #fff5e0;
    --green-dark-trans: #41b06d14;
    --green-extra-dark: #198754;
  }
.nav{
    background-color: var(--green-dark-trans);
}
.nav-pills .nav-link {
    text-transform: uppercase;
    word-spacing: 2px;
    color: black;
    background-color: transparent; 
}
  .nav-pills .nav-link.active {
    color: var(--green-dark);
    background-color: transparent;
  }