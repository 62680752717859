.nav-color{
    transition: color 0.3s ease;
}.nav-color:hover{
       color: #41B06E !important;
}
.nav-color:active{
    color: #41B06E !important;
}
.nav-color:focus{
        color: #41B06E !important;

}
.nav-logo{
    width:3rem;
}