.container{
    background-color:whitesmoke;
    color:  #41b06e;
    border-radius: 10px;
   box-shadow:  0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width:500px;
    height: 320px;
    margin-top: 150px;

    display:flex;
    justify-content: center;

}
@media (max-width:600px) {
    .container{
        width:300px;
    }
}
.navy{
    color: #141e46;
}